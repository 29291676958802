<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import type { HCaptchaVerifiedEvent } from "../types";

  const hCaptchaPromise = import("vanilla-hcaptcha");

  const dispatchEvent = createEventDispatcher<{
    error: string,
    verified: string
  }>();

  const dispatchError = ({ error }: ErrorEvent) => dispatchEvent("error", error);
  const dispatchVerified = ({ token }: HCaptchaVerifiedEvent) => dispatchEvent("verified", token);

  const { VITE_HCAPTCHA_SITEKEY: sitekey } = import.meta.env;
</script>

{#await hCaptchaPromise then _}
  <h-captcha
    reCaptchaCompat={false}
    site-key={sitekey}
    on:error={dispatchError}
    on:verified={dispatchVerified}
  ></h-captcha>
{/await}

<style lang="postcss">
  h-captcha {
    align-self: center;
    margin-top: var(--font-size-4);
  }
</style>
