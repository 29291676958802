<script lang="ts">
  import backIcon from "@assets/icons/back.svg";
  import { createEventDispatcher } from "svelte";
  import { fade } from "svelte/transition";
  import IconButton from "@components/IconButton.svelte";
  import { navigate } from "svelte-navigator";
  import TextButton from "@components/TextButton.svelte";

  export let name: string;
  export let icon: string | null = null;
  export let instruction: string | null = null;
  export let error: string | null = null;
  export let isForm: boolean = false;
  export let formAttributes: Record<string, string> = {};
  export let actionDisabled: boolean = false;
  export let actionName: string | null = "Done";

  $: additionalContentElementAttributes = isForm ? formAttributes : {};
  $: contentElementType = isForm ? "form" : "div";

  const dispatchEvent = createEventDispatcher();

  const dispatchActionEvent = () => dispatchEvent("action");
</script>

<main class="outer">
  <div class="inner">
    <div class="options">
      <slot name="options">
        <IconButton
          icon={backIcon}
          title="Back"
          on:click={() => navigate(-1)}
        />
      </slot>
    </div>

    <svelte:element
      this={contentElementType}
      class="content"
      {...additionalContentElementAttributes}
      on:submit|preventDefault={dispatchActionEvent}
    >
      {#if icon}
        <img
          src={icon}
          alt={name}
          class="icon"
        >
      {:else}
        <h2>{name}</h2>
      {/if}

      {#if instruction}
        <p class="instruction">{instruction}</p>
      {/if}

      <slot />

      {#if actionName}
        <div class="action">
          {#if isForm}
            <TextButton
              type="submit"
              disabled={actionDisabled}
            >
              {actionName}
            </TextButton>
          {:else}
            <TextButton
              disabled={actionDisabled}
              on:click={dispatchActionEvent}
            >
              {actionName}
            </TextButton>
          {/if}
        </div>
      {/if}
    </svelte:element>

    {#if error}
      <div
        class="error"
        transition:fade={{ duration: 250 }}
      >
        {error}
      </div>
    {/if}
  </div>
</main>

<style lang="postcss">
  .inner {
    --margin: var(--size-6);

    background: var(--gray-0);
    border-radius: var(--radius-3);
    height: calc(var(--size-15) + var(--margin));
    padding: var(--size-8) var(--size-fluid-2);
    position: relative;
    width: min(var(--size-15), 100% - var(--margin) * 2);

    @media (prefers-color-scheme: dark) {
      background: var(--gray-9);
    }
  }

  .outer {
    align-items: center;
    background-color: var(--color-primary);
    display: flex;
    font-family: "forma-djr-display", var(--font-sans);
    height: 100vh;
    height: 100dvh;
    justify-content: center;
    width: 100%;
  }

  h2 {
    font-size: var(--font-size-fluid-3);
    text-align: center;

    & + :global(:not(.instruction)) {
      margin-top: var(--font-size-6);
    }
  }

  .action {
    margin-top: var(--font-size-7);
    width: 100%;
     
    & :global(button) {
      font-size: var(--font-size-3);
      margin: auto;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  .error {
    background: var(--red-5);
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    bottom: 0;
    color: var(--gray-9);
    font-size: var(--font-size-1);
    left: 0;
    line-height: var(--font-lineheight-1);
    padding: 0.25em 0.5em;
    position: absolute;
    right: 0;
    text-align: center;
  }
  
  .icon {
    align-self: center;
    width: var(--size-content-1);

    @media (prefers-color-scheme: dark) {
      filter: invert(100%);
    }

    & + .instruction {
      font-size: var(--font-size-4);
    }
  }

  .instruction {
    align-self: center;
    font-size: var(--font-size-3);
    font-style: italic;
    line-height: var(--font-lineheight-1);
    margin-top: var(--font-size-4);
    text-align: center;
    width: 80%;

    & + :global(*) {
      margin-top: var(--font-size-7);
    }
  }

  .options {
    display: flex;
    justify-content: space-between;
    left: 0.7em;
    position: absolute;
    right: 0.7em;
    top: 0.7em;
  }
</style>
