<script lang="ts">
  import checkIcon from "@assets/icons/check.svg";
  import HCaptcha from "@/lib/components/HCaptcha.svelte";
  import SimplePage from "@/lib/components/SimplePage.svelte";
  import supabaseClient from "@utils/supabase";
  import TextInput from "@components/TextInput.svelte";
  
  let captchaError: string | null = null;
  let captchaToken: string | null = null;
  let email: string;
  let emailError: string | null = null;
  let sending = false;
  let sent = false;

  const formAttributes = {
    novalidate: ""
  };

  const handleCaptchaVerified = ({ detail: token }: CustomEvent<string>) => {
    captchaError = null;
    captchaToken = token;
  };

  const login = async () => {
    sending = true;

    const urlString = import.meta.url;

    const url = urlString ? new URL(urlString) : undefined;

    if (email === undefined || email === "") {
      emailError = "Please enter your email.";
      sending = false;
      return;
    }

    if (!captchaToken) {
      captchaError = "Captcha hasn't been verified.";
      sending = false;
      return;
    }

    const { error } = await supabaseClient.auth.signInWithOtp({
      email,
      options: {
        captchaToken, 
        emailRedirectTo: url?.origin
      }
    });

    if (error !== null) {
      emailError = error.message;
      sending = false;
      return;
    }

    emailError = null;
    sending = false;
    sent = true;
  };

  $: emailIsInvalid = typeof emailError === "string";
</script>

{#if sent}
  <SimplePage
    name="Log In"
    icon={checkIcon}
    instruction="A magic link has been sent to your inbox."
    actionName={null}
  >
    <span slot="options" />
  </SimplePage>
{:else}
  <SimplePage
    name="Log In"
    instruction="A magic link will be sent to your inbox."
    error={emailError ?? captchaError ?? null}
    isForm
    {formAttributes}
    actionDisabled={sending}
    actionName="Send magic link"
    on:action={login}
  >

    <span slot="options" />

    <div class="field">
      <label for="email">Your email</label>

      <TextInput
        type="email"
        name="email"
        invalid={emailIsInvalid}
        placeholder="me@wibbo.dog"
        autocomplete="username"
        on:input={() => emailError = null}
        bind:value={email}
      />
    </div>

    <HCaptcha
      on:error={({ detail: error }) => captchaError = error}
      on:verified={handleCaptchaVerified}
    />
  </SimplePage>
{/if}

<style lang="scss">
  .field {
    align-self: center;
    display: flex;
    flex-direction: column;
    width: min(100%, 40ch);
  }

  label {
    font-size: var(--font-size-3);
    margin-bottom: 0.25em;
  }
</style>
