<script lang="ts">
  export let icon: string;
  export let title: string;
  export let dim: boolean = false;
</script>

<button
  type="button"
  {title}
  class:dim
  on:click
>
  <img
    src={icon}
    alt=""
  >
</button>

<style lang="postcss">
  :where(button) {
    background: none;
    opacity: 1;
    padding: 0;

    &:hover {
      opacity: 0.8;
      transition: opacity 0.15s ease-in-out;
    }

    &.dim {
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }

    & img {
      height: var(--font-size-5);
      width: var(--font-size-5);

      @media (prefers-color-scheme: dark) {
        filter: invert(100%);
      }
    }
  }
</style>
