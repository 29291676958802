<script lang="ts">
  import random from "just-random";

  const messages = [
    "Congratulations!",
    "Well done!"
  ];

  const message = random(messages);
</script>

<div>
  <h2>{message}</h2>
</div>
