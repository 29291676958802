<script lang="ts">
  import downIcon from "@assets/icons/down.svg";
  import { navigate } from "svelte-navigator";
  import { slide } from "svelte/transition";
  import TextButton from "@components/TextButton.svelte";
    
  export let start: number;
  let isExpanded = false;

  $: topValue = `top: ${start}px`;
</script>

<button
  type="button"
  title={`${isExpanded ? "Close" : "Open"} menu`}
  class="toggle"
  on:click={() => isExpanded = !isExpanded}
>
  <h1>Where's Wibbo?</h1>

  <img
    src={downIcon}
    alt="Timer"
    class="down-icon"
  >
</button>

{#if isExpanded}
  <div
    class="expandable"
    style={topValue}
    transition:slide
  >
    <TextButton on:click={() => navigate("/profile")}>Your profile</TextButton>
    <TextButton>Submit a picture</TextButton>
    <TextButton>Send feedback</TextButton>
    <TextButton>Install the app</TextButton>
  </div>
{/if}

<style lang="scss">
  h1 {
    color: var(--color-text-secondary);
    font-size: var(--font-size-fluid-2);
    transform: translateY(calc(-1 * var(--font-size-fluid-0)));
  }

  .toggle {
    background: none;
    padding: 0;
    position: relative;
  }

  .expandable {
    align-items: center;
    background: var(--color-secondary);
    border-bottom-left-radius: var(--radius-3);
    border-bottom-right-radius: var(--radius-3);
    border-top: var(--border-size-1) solid var(--color-primary);
    display: flex;
    flex-direction: column;
    left: 50%;
    padding: var(--font-size-fluid-2);
    position: absolute;
    transform: translateX(-50%);
    width: min(var(--size-content-3), 100%);
    z-index: 1;

    :global(button) {
      background: var(--gray-0);
      color: var(--color-text-dark);
      font-size: var(--font-size-3);
      width: 100%;

      &:hover {
        font-size: var(--font-size-4);
        transition: font-size 0.25s ease-in-out;
      }

      &:nth-of-type(2n) {
        background: var(--gray-3);
      }

      &:not(:first-of-type) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &:not(:last-of-type) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .down-icon {
    bottom: calc(-1 * var(--font-size-fluid-0));
    filter: invert(100%);
    height: var(--font-size-fluid-1);
    left: 50%;
    position: absolute;
    transform: translateX(-50%);

    @media (prefers-color-scheme: dark) {
      filter: unset;
    }
  }
</style>
