import { upload as uploadAvatar } from "@utils/avatar";
import { createClient, type PostgrestError, type User } from "@supabase/supabase-js";
import { derived, readable, type Readable } from "svelte/store";
import type { FormattedAvatar, Profile, Result } from "@/lib/types";

const { VITE_SUPABASE_URL: supabaseUrl, VITE_SUPABASE_ANON_KEY: supabaseAnonKey } = import.meta.env;
const client = createClient(supabaseUrl, supabaseAnonKey);

export default client;

export const user = readable<User | null>(null, set => {
  client.auth.getSession()
    .then(({ data: { session } }) => {
      const newUser = session?.user ?? null;
      set(newUser);  
    });
  
  const { data: authStateChangeListener } = client.auth.onAuthStateChange((_, session) => {
    const newUser = session?.user ?? null;
    set(newUser);
  });

  return authStateChangeListener?.subscription.unsubscribe;
});

const getProfile = async (currentUser: User): Promise<Result<Profile, PostgrestError>> => {
  const { data, error } = await client
    .from("profiles")
    .select("username, avatar")
    .eq("id", currentUser.id)
    .single();
  
  if (data) {
    return {
      data: {
        ...data,
        email: currentUser.email!
      },

      error: null
    };
  }

  return {
    data: null,
    error
  };
};

export const setProfile = async (newUsername?: string, newAvatar?: FormattedAvatar): Promise<Result<undefined>> => {
  const { data: { session } } = await client.auth.getSession();
  const profileUser = session?.user;

  let newAvatarFileName: string | undefined = undefined;
  
  if (!profileUser) {
    return {
      data: null,
      error: new Error("You are not logged in.")
    };
  }
  
  if (newAvatar) {
    const { data: uploadData, error: uploadError } = await uploadAvatar(newAvatar);
    
    if (uploadError || !uploadData) {
      const message = uploadError?.message ?? "An error occurred.";
      
      return {
        data: null,
        error: new Error(message)
      };
    }
    
    newAvatarFileName = uploadData.path;
  }
  
  const { error } = await client
    .from("profiles")
    .update({
      avatar: newAvatarFileName,
      username: newUsername
    })
    .eq("id", profileUser.id);
  
  if (error) {
    const message = error.message ?? "An error occurred.";
    
    return {
      data: null,
      error: new Error(message)
    };
  }

  return {
    data: undefined,
    error: null
  };
};

export const profile = derived<Readable<User | null>, Result<Profile> | undefined>(user, ($user, set) => {
  if (!$user) {
    set({
      data: null,
      error: new Error("You are not logged in.")
    });

    return;
  }
  
  getProfile($user)
    .then(({ error, data }) => {
      if (error) {
        const message = error?.message ?? "An error occurred.";

        set({
          data: null,
          error: new Error(message)
        });

        return;
      }
    
      set({
        data,
        error: null
      });
    });
});
