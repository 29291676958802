<script lang="ts">
  import getImage from "@utils/image";
  import popupDetails from "@utils/popup-details";
  import timer, { timerIsPaused } from "@utils/timer";

  import ImageDetailsView from "@components/ImageDetailsView.svelte";
  import imageIcon from "@assets/icons/image.svg";
  import Menu from "@components/Menu.svelte";
  import timerIcon from "@assets/icons/timer.svg";

  let height: number;

  const image = getImage();

  $: timerMinutes = Math.floor($timer?.total("minutes") ?? 0).toLocaleString("en-AU", {
    minimumIntegerDigits: 2
  });

  $: timerSeconds = Math.floor($timer ? $timer.total("seconds") % 60 : 0).toLocaleString("en-AU", {
    minimumIntegerDigits: 2
  });

  $: timerString = `${timerMinutes}:${timerSeconds}`;

  const requestImageDetailsPopup = async () => {
    $popupDetails = {
      blurred: true,
      element: ImageDetailsView,
      props: {
        image: await image
      }
    };
  };

  const toggleTimerIsPaused = () => timerIsPaused.update(isPaused => !isPaused);
</script>

<header bind:clientHeight={height}>
  <div class="content">
    <button
      title="Pause timer"
      on:click={toggleTimerIsPaused}
    >
      <img
        src={timerIcon}
        alt="Timer"
        class="icon"
      >

      <span class="detail">
        {timerString}
      </span>
    </button>
  </div>

  <div class="content">
    <Menu start={height} />
  </div>

  <div class="content">
    <button on:click={requestImageDetailsPopup}>
      <img
        src={imageIcon}
        alt="Details"
        class="icon"
      >

      <span class="detail">
        {#await image then imageDetails}
          {imageDetails.author.username} - {imageDetails.parsed_date_taken.toLocaleString("en-AU")}
        {/await}
      </span>
    </button>
  </div>
</header>

<style lang="scss">
  header {
    align-items: center;
    background: var(--color-secondary);
    color: var(--color-text-secondary);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin-bottom: var(--size-fluid-4);
    padding: var(--size-fluid-2) var(--size-fluid-4);
    width: 100%;
  }

  .content {
    display: flex;

    &:last-of-type {
      justify-content: flex-end;

      button {
        flex-direction: row-reverse;
      }
    }

    button {
      align-items: center;
      background: none;
      display: flex;
      padding: 0;
    }
  }

  .icon {
    filter: invert(100%);
    height: var(--font-size-fluid-2);
    width: var(--font-size-fluid-2);

    @media (prefers-color-scheme: dark) {
      filter: unset;
    }
  }

  .detail {
    font-size: var(--font-size-fluid-1);
    margin-left: 1ch;
    margin-right: 1ch;

    @media (max-width: 64em) {
      display: none;
    }
  }
</style>
