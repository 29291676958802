<script lang="ts">
  import Header from "@components/Header.svelte";
  import Popup from "@components/Popup.svelte";
  import PuzzleDisplay from "@components/PuzzleDisplay.svelte";

  let root: HTMLElement, headerHeight: number, windowHeight: number;
  
  $: color = root ? getComputedStyle(root).color : "#000";
  $: font = root ? getComputedStyle(root).fontFamily : "sans-serif";
  $: availablePuzzleHeight = Math.floor(windowHeight) - Math.ceil(headerHeight);
</script>

<svelte:window bind:innerHeight={windowHeight} />

<main bind:this={root}>
  <div
    class="header-wrapper"
    bind:clientHeight={headerHeight}
  >
    <Header />
  </div>

  <Popup />
  
  <PuzzleDisplay
    availableHeight={availablePuzzleHeight}
    {color}
    {font}
  />
</main>

<style lang="scss">
  main {
    align-items: center;
    background: var(--color-primary);
    display: flex;
    flex-direction: column;
    font-family: "forma-djr-display", var(--font-sans);
  }

  .header-wrapper {
    width: 100%;
  }
</style>
