<script lang="ts">
  import closeIcon from "@assets/icons/close.svg";
  import IconButton from "./IconButton.svelte";
  import popupDetails from "@utils/popup-details";
  import { timerIsPaused } from "@utils/timer";

  let popup: HTMLDialogElement;

  $: {
    if ($popupDetails === null) {
      popup?.close();
      $timerIsPaused = false;
    } else {
      popup?.showModal();
      $timerIsPaused = true;
    }
  }

  const handleCancel = (event: Event) => {
    if ($popupDetails?.fixed) {
      event.preventDefault();
    }
  };

  const handleClose = () => {
    if (!$popupDetails?.fixed) {
      $popupDetails = null;
    }
  };
</script>

<dialog
  class={$popupDetails?.blurred ? "blurred" : ""}
  on:cancel={handleCancel}
  on:close={handleClose}
  bind:this={popup}
>
  {#if !$popupDetails?.fixed}
    <div class="close">
      <IconButton
        dim
        icon={closeIcon}
        title="Close"
        on:click={() => popup.close()}
      />
    </div>
  {/if}

  {#if $popupDetails !== null}
    <svelte:component
      this={$popupDetails.element}
      {...$popupDetails.props}
      on:close={handleClose}
    />
  {/if}
</dialog>

<style lang="scss">
  dialog {
    padding: 2rem;
    position: relative;

    &::backdrop {
      all: revert;
    }

    &.blurred::backdrop {
      backdrop-filter: blur(1rem);
    }
  }

  .close {
    padding: 1em;
    position: absolute;
    right: 0;
    top: 0;
  }
</style>
