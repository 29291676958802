import client from "@utils/supabase";
import { pEvent } from "p-event";
import type { FormattedAvatar, Profile, Result, SupabaseFileUploadData } from "@/lib/types";
import type { JdenticonConfig } from "jdenticon";

const bucket = "avatars";
const defaultContentType = "image/svg+xml";
const defaultSize = 250;
const quality = 20;

const defaultOptions: JdenticonConfig = {
  backColor: "#8e86c6",

  saturation: {
    grayscale: 0.2,
    color: 0.8
  }
};

const createName = async (contentType: string): Promise<string> => {
  const [{ default: { getExtension }}, { v4: uuid }] = await Promise.all([
    await import("mime"),
    await import("uuid")
  ]);

  const extension = getExtension(contentType) as string;
  const name = uuid();

  return `${name}.${extension}`;
};

export const createObjectUrl = (avatar: FormattedAvatar): string => {
  const blob = new Blob([
    avatar.buffer
  ], {
    type: avatar.contentType
  });

  return URL.createObjectURL(blob);
}

export const format = async (avatar: File): Promise<FormattedAvatar> => {
  const [{ default: jimp }, { default: smartcrop }] = await Promise.all([
    await import("jimp/browser/lib/jimp.js"),
    await import("smartcrop")
  ]);

  const arrayBuffer = await avatar.arrayBuffer();
  const buffer = Buffer.from(arrayBuffer);
  
  const objectUrl = URL.createObjectURL(avatar);
  const image = new Image();
  
  image.src = objectUrl;
  await pEvent(image, "load");

  URL.revokeObjectURL(objectUrl);
  
  const { topCrop: cropRegion } = await smartcrop.crop(image, {
    height: 250,
    width: 250
  });

  const jimpInstance = await jimp.read(buffer);
  const contentType = jimpInstance.getMIME();
  
  jimpInstance
    .crop(cropRegion.x, cropRegion.y, cropRegion.width, cropRegion.height)
    .quality(quality);
  
  const newBuffer = await jimpInstance.getBufferAsync(contentType);

  return {
    buffer: newBuffer,
    contentType
  };
};

export const download = async (profile: Profile): Promise<string> => {
  if (!profile.avatar) {
    const { toSvg } = await import("jdenticon");
    const defaultAvatarString = toSvg(profile.username, defaultSize, defaultOptions);
    
    const defaultAvatarBlob = new Blob([
      defaultAvatarString
    ], {
      type: defaultContentType
    });

    return URL.createObjectURL(defaultAvatarBlob);
  }

  const { data: avatar } = await client
    .storage
    .from("avatars")
    .download(profile.avatar);

  if (!avatar) {
    throw new Error("Failed to download avatar.");
  }

  return URL.createObjectURL(avatar);
};

export const upload = async (avatar: FormattedAvatar): Promise<Result<SupabaseFileUploadData>> => {  
  const { buffer, contentType } = avatar;

  const path = await createName(contentType);

  const result = await client
    .storage
    .from(bucket)
    .upload(path, buffer, {
      contentType,
      upsert: false
    });
  
  return result;
};
