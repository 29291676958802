<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let invalid: boolean = false;
  export let name: string;
  export let readonly: boolean = false;
  export let type: string = "text";
  export let value: string = "";

  const dispatch = createEventDispatcher();

  const handleInput = (event: Event) => {
    const element = event.target as HTMLInputElement;

    value = element.value;
    dispatch("input");
  };
</script>

<input
  {...$$restProps}
  {type}
  id={name}
  {name}
  {readonly}
  aria-invalid={invalid}
  class:invalid
  {value}
  on:input={handleInput}
>

<style lang="postcss">
  :where(input) {
    cursor: text;
    font-size: var(--font-size-1);
    outline: var(--border-size-1) solid transparent;
    outline-offset: 0;
    padding: 0.75em;
    transition: outline 0.25s ease-in-out;

    &.invalid,
    &:invalid {
      outline-color: var(--red-8);
    }

    &:focus:not(:read-only) {
      outline-color: var(--color-light);
    }

    &:read-only {
      cursor: not-allowed;

      &:focus:not(:invalid, .invalid) {
        outline: none;
      }
    }
  }
</style>
