<script lang="ts">
  import type { Coordinate, ImageDetails } from "@/lib/types";
  import { drawCenteredImage, type RenderParams } from "@utils/canvas";
  import getImage from "@utils/image";
  import pointInPolygon from "point-in-polygon";
  import popupDetails from "@utils/popup-details";

  import { Canvas, Layer } from "svelte-canvas";
  import SuccessView from "@components/SuccessView.svelte";

  export let availableHeight: number, color: string, font: string;

  let canvas: Canvas, coordinatesInCanvas: Coordinate[], puzzleElement: HTMLDivElement, puzzleWidth: number;
  let image: ImageDetails | null = null;

  $: puzzleHeight = puzzleElement ? availableHeight - parseInt(getComputedStyle(puzzleElement).paddingBottom) : null;
  
  $: render = ({ context, height, width }: RenderParams) => {
    context.fillStyle = color;
    context.font = `bold 32px ${font}`;
    context.textAlign = "center";
    context.textBaseline = "middle";

    context.fillText("Loading...", width / 2, height / 2, width - 100);

    if (image) {
      const { coordinates, element } = image;

      const { scale, horizontalPadding, verticalPadding } = drawCenteredImage(element, context, width, height);
     
      coordinatesInCanvas = coordinates.map(([ x, y ]) => {
        const canvasX = x * scale + horizontalPadding;
        const canvasY = y * scale + verticalPadding;

        return [
          canvasX,
          canvasY
        ];
      });
    }
  };

  const setup = async () => {
    await document.fonts.ready;
    canvas.redraw();
  };

  const handleCanvasClick = (event: MouseEvent) => {
    const guess: Coordinate = [
      event.offsetX,
      event.offsetY
    ];

    const guessIsCorrect = pointInPolygon(guess, coordinatesInCanvas);

    if (guessIsCorrect) {
      $popupDetails = {
        element: SuccessView,
        fixed: true
      };
    }
  };

  getImage().then(resolvedImage => image = resolvedImage)
</script>

<div
  class="puzzle"
  style:--availableHeight={availableHeight}
  bind:clientWidth={puzzleWidth}
  bind:this={puzzleElement}
>
  {#if (puzzleWidth && puzzleHeight)}
    <Canvas
      width={puzzleWidth}
      height={puzzleHeight}
      on:click={handleCanvasClick}
      bind:this={canvas}
    >
      <Layer {render} {setup} />
    </Canvas>
  {:else}
    <h2>Loading...</h2>
  {/if}
</div>

<style lang="scss">
  .puzzle {
    --padding-side: var(--size-fluid-6);

    height: calc(var(--availableHeight) * 1px);
    padding-bottom: var(--size-fluid-4);
    width: calc(100% - var(--padding-side));

    @media (max-width: 30em) {
      --padding-side: var(--size-fluid-4);
    }
  }
</style>
