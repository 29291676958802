import type { Render } from "svelte-canvas";

export interface ImageDrawDetails {
  scale: number;
  horizontalPadding: number;
  verticalPadding: number;
};

export type RenderParams = Parameters<Render>[0];

export const drawCenteredImage = (
  image: HTMLImageElement,
  context: CanvasRenderingContext2D,
  canvasWidth: number,
  canvasHeight: number
): ImageDrawDetails => {
  const { width: sourceWidth, height: sourceHeight } = image;

  const heightScale = canvasHeight / sourceHeight;
  const widthScale = canvasWidth / sourceWidth;

  const scale = Math.min(heightScale, widthScale);

  const destinationWidth = sourceWidth * scale;
  const destinationHeight = sourceHeight * scale;

  const remainingWidth = canvasWidth - destinationWidth;
  const remainingHeight = canvasHeight - destinationHeight;

  const horizontalPadding = remainingWidth / 2;
  const verticalPadding = remainingHeight / 2;

  const drawImageArguments: Parameters<CanvasRenderingContext2D["drawImage"]> = [
    image,
    0,
    0,
    sourceWidth,
    sourceHeight,
    horizontalPadding,
    verticalPadding,
    destinationWidth,
    destinationHeight
  ];

  context.drawImage(...drawImageArguments);

  return {
    scale,
    horizontalPadding,
    verticalPadding
  };
};
