<script lang="ts">
  import TextButton from "@components/TextButton.svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
</script>

<div>
  <h2>The Game is Paused</h2>

  <TextButton
    on:click={() => dispatch("close")
  }>
    Resume
  </TextButton>
</div>

<style lang="postcss">
  div {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;

    & :global(button) {
      margin-top: var(--font-size-fluid-2);
    }
  }
</style>
