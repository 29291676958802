import App from "@/App.svelte";
import setupAnalytics from "./lib/utils/analytics";
import "@/lib/global.css";

const root = document.querySelector("[data-root]") as HTMLDivElement;

export default new App({
  target: root
});

setupAnalytics();
