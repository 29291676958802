import client from "./supabase";
import type { ImageDetails, RawImageDetails } from "../types";
import { pEvent } from "p-event";
import { Temporal } from "@js-temporal/polyfill";

const bucket = "images";

export default async () => {
  const result = await client
    .from("images")
    .select(`
      author (
        username
      ),
      coordinates,
      date_taken,
      extension,
      id,
      location
    `)
    .single();
  
  const rawImage = result.data as RawImageDetails;
  const fileName = `${rawImage.id}.${rawImage.extension}`;
  
  const { data: imageBlob } = await client
    .storage
    .from(bucket)
    .download(fileName);
  
  const objectUrl = URL.createObjectURL(imageBlob!);
  const element = new Image();
  
  element.src = objectUrl;
  await pEvent(element, "load");
  
  const formattedDate = Temporal.PlainDate.from(rawImage.date_taken);
  
  const image: ImageDetails = {
    ...rawImage,
    element,
    parsed_date_taken: formattedDate
  }
  
  return image;
};
