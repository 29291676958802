import type { PopupDetails } from "@/lib/types";
import { timerIsPaused } from "@utils/timer";
import { writable } from "svelte/store";

import PausedView from "@components/PausedView.svelte";

const popupDetails = writable<PopupDetails | null>(null);
let isNull = true;

popupDetails.subscribe(newPopupDetails => isNull = newPopupDetails === null);

timerIsPaused.subscribe(isPaused => {
  if (isNull && isPaused) {
    popupDetails.set({
      blurred: true,
      element: PausedView
    });
  };
});

export default popupDetails;
