import { derived, writable } from "svelte/store";
import { Temporal } from "@js-temporal/polyfill";

export const timerIsPaused = writable(false);

addEventListener("visibilitychange", () => {
  const pageIsHidden = document.visibilityState === "hidden";

  timerIsPaused.update(isPaused => {
    if (pageIsHidden && !isPaused) {
      return true;
    }

    return isPaused;
  });
});

let timer = new Temporal.Duration();

export default derived<typeof timerIsPaused, Temporal.Duration | null>(timerIsPaused, ($timerIsPaused, set) => {
  if ($timerIsPaused) {
    return;
  }

  const interval = setInterval(() => {
    timer = timer.add({
      seconds: 1
    });

    set(timer);
  }, 1000);

  return () => clearInterval(interval);
});
