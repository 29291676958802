<script lang="ts">
  import { createObjectUrl as createAvatarObjectUrl, download as downloadAvatar, format as formatAvatar } from "@utils/avatar";
  import type { FormattedAvatar, Profile } from "@/lib/types";
  import IconButton from "@components/IconButton.svelte";
  import { navigate } from "svelte-navigator";
  import SimplePage from "@/lib/components/SimplePage.svelte";
  import supabaseClient, { profile, setProfile } from "@utils/supabase";
  import TextInput from "@components/TextInput.svelte";
  import toSpliced from "array.prototype.tospliced";

  import backIcon from "@assets/icons/back.svg";
  import logoutIcon from "@assets/icons/logout.svg";

  let error: Error | null = null;
  let invalidFields: (keyof Profile)[] = [];
  let saving = false;

  let email = "";
  let newAvatar: FormattedAvatar | null = null;
  let oldAvatar: string | null = null;
  let username = "";

  $: avatar = newAvatar ? createAvatarObjectUrl(newAvatar) : oldAvatar;
  $: changed = newAvatar !== null || ($profile?.data && username && username !== $profile.data.username);

  const setInitialValues = async (profile: Profile) => {
    email = profile.email;
    username = profile.username;
    
    const avatarObjectUrl = await downloadAvatar(profile)
    oldAvatar = avatarObjectUrl;
  }

  $: if ($profile?.data) {
    setInitialValues($profile.data);
  }

  const logout = () => supabaseClient.auth.signOut();

  const handleAvatarInput = async (event: Event) => {
    const fileInput = event.target as HTMLInputElement;

    if (!fileInput.files) {
      newAvatar = null;
      return;
    }

    const [ file ] = fileInput.files;

    if (!file.type.startsWith("image/")) {
      newAvatar = null;
      return;
    }

    const formattedAvatar = await formatAvatar(file);
    
    newAvatar = formattedAvatar;
  };

  const handleUsernameInput = () => {
    const invalidFieldsIndex = invalidFields.indexOf("username");

    if (invalidFieldsIndex !== -1) {
      invalidFields = toSpliced(invalidFields, invalidFieldsIndex, 1);
      error = null;
    }
  };

  const handleSave = async () => {
    saving = true;

    const newUsername = username.trim();

    if (newUsername.length === 0) {
      invalidFields = [
        ...invalidFields,
        "username"
      ];

      error = new Error("Please enter a username.");
      
      saving = false;
      return;
    }

    const { default: ProfanityChecker } = await import("bad-words");

    const profanityChecker = new ProfanityChecker();
    const isProfane = profanityChecker.isProfane(newUsername);

    if (isProfane) {
      invalidFields = [
        ...invalidFields,
        "username"
      ];

      error = new Error("That username is profane!");

      saving = false;
      return;
    }

    const { error: setProfileError } = await setProfile(newUsername, newAvatar ?? undefined);

    if (setProfileError) {
      error = setProfileError;
      saving = false;
      return;
    }

    location.reload();
  };
</script>

<SimplePage
  actionName="Save changes"
  actionDisabled={saving || !changed}
  error={error?.message ?? null}
  name="Your Profile"
  on:action={handleSave}
>
  <svelte:fragment slot="options">
    <IconButton
      icon={backIcon}
      title="Back"
      on:click={() => navigate(-1)}
    />

    <IconButton
      icon={logoutIcon}
      title="Log out"
      on:click={logout}
    />
  </svelte:fragment>

  <div class="info">
    <label for="email">Email</label>

    <TextInput
      type="email"
      readonly
      name="email"
      invalid={invalidFields.includes("email")}
      bind:value={email}
    />

    <label for="username">Username</label>
  
    <TextInput
      name="username"
      invalid={invalidFields.includes("username")}
      on:input={handleUsernameInput}
      bind:value={username}
    />

    <label for="avatar">Avatar</label>

    <div class="avatar">
      <input
        type="file"
        accept="image/*"
        id="avatar"
        class={invalidFields.includes("avatar") ? "invalid": ""}
        on:change={handleAvatarInput}
      >

      {#if avatar}
        <img
          src={avatar}
          alt="Preview of your avatar"
        >
      {/if}
    </div>
  </div>
</SimplePage>

<style lang="scss">
  .info {
    align-items: center;
    column-gap: 1.5ch;
    display: grid;
    font-size: var(--font-size-fluid-1);
    grid-template-columns: max-content auto;
    row-gap: 0.5em;

    @media (max-width: 30em) {
      grid-template-columns: 1fr;
      row-gap: 0.25em;

      :nth-child(2n) {
        margin-bottom: 1em;
      }
    }
  }

  .avatar {
    position: relative;
    width: 100%;

    img {
      border-radius: var(--radius-2);
      height: calc(100% - var(--border-size-3) * 2);
      position: absolute;
      right: var(--border-size-3);
      top: 50%;
      transform: translateY(-50%);
    }

    input {
      cursor: pointer;
      font-size: var(--font-size-1);
      padding: 0.75em 8ch 0.75em 0.75em;
      width: 100%;

      &::file-selector-button {
        background: var(--color-light);
        border: 0;
        border-radius: 1vmax;
        color: var(--color-text-secondary);
        cursor: pointer;
        font-family: inherit;
        font-size: var(--font-size-1);
        margin-right: 1em;
        padding: 0.5em 0.75em;

        @media (prefers-color-scheme: dark) {
          background: var(--gray-0);
        }

        &:hover {
          opacity: 0.8;
          transition: opacity 0.15s ease-in-out;
        }
      }
    }
  }
</style>
