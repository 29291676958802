<script lang="ts">
  import { globalHistory, navigate, Route, Router } from "svelte-navigator";
  import type { GlobalHistoryChange } from "./lib/types";
  import type { Readable } from "svelte/store";
  import { user } from "@utils/supabase";
  
  import Game from "@pages/Game.svelte";
  import Login from "@pages/Login.svelte";
  import Profile from "@pages/Profile.svelte";

  const historyStore: Readable<GlobalHistoryChange> = {
    subscribe: globalHistory.listen
  };

  $: {
    if ($user === null && $historyStore.location.pathname !== "/login") {
      navigate("/login", {
        replace: true
      });
    }
    
    if ($user !== null && $historyStore.location.pathname === "/login") {
      navigate("/", {
        replace: true
      });
    }
  }
</script>

<Router>
  <Route path="/">
    <Game />
  </Route>

  <Route path="login">
    <Login />
  </Route>

  <Route path="profile">
    <Profile />
  </Route>
</Router>
