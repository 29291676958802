import type { AnalyticsOptions, AnalyticsRequest } from "@/lib/types";
import { BrowserTracing } from "@sentry/tracing";
import { globalHistory } from "svelte-navigator";
import * as sentry from "@sentry/svelte";
import { inject as injectAudienceAnalytics } from "@vercel/analytics";
import { onCLS, onFCP, onFID, onLCP, onTTFB, type Metric } from "web-vitals";

const sendMetric = (metric: Metric, { analyticsId, analyticsUrl, href, page }: AnalyticsOptions) => {
  const speed = navigator.connection?.effectiveType ?? "";
  const value = metric.value.toString();

  const requestBody: AnalyticsRequest = {
    dsn: analyticsId,
    event_name: metric.name,
    href,
    id: metric.id,
    page,
    speed,
    value
  };

  const searchParams = new URLSearchParams(requestBody as unknown as Record<string, string>);
  navigator.sendBeacon(analyticsUrl, searchParams);
};

export default () => {
  const { VITE_SENTRY_DSN: sentryDsn, VITE_VERCEL_ANALYTICS_ID: vercelAnalyticsId, VITE_VERCEL_ANALYTICS_URL: vercelAnalyticsUrl, VITE_VERCEL_ENV: environment } = import.meta.env;
  const mode = environment === "production" ? "production" : "development";  

  sentry.init({
    dsn: sentryDsn,
    enabled: mode === "production",
    environment,

    integrations: [
      new BrowserTracing()
    ]
  });

  injectAudienceAnalytics({
    mode
  });

  globalHistory.listen(({ location: routerLocation }) => {
    if (vercelAnalyticsId && mode === "production") {
      const send = (metric: Metric) => sendMetric(metric, options);

      const options: AnalyticsOptions = {
        analyticsId: vercelAnalyticsId,
        analyticsUrl: vercelAnalyticsUrl,
        href: location.href,
        page: routerLocation.pathname
      };

      onCLS(send);
      onFCP(send);
      onFID(send);
      onLCP(send);
      onTTFB(send);
    }
  });
};
