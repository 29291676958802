<script lang="ts">
  import type { ImageDetails } from "@/lib/types";
  import { Intl, Temporal } from "@js-temporal/polyfill";

  import calendarIcon from "@assets/icons/calendar.svg";
  import downloadIcon from "@assets/icons/download.svg";
  import personIcon from "@assets/icons/person.svg";
  import locationIcon from "@assets/icons/location.svg";

  export let image: ImageDetails;

  const dateFormatter = new Intl.DateTimeFormat("en-AU", {
    dateStyle: "full"
  });

  const currentDate = Temporal.Now.plainDateISO();

  $: imageFormattedDate = dateFormatter.format(image.parsed_date_taken);
  $: currentFormattedDate = currentDate.toLocaleString("en-AU").replaceAll("/", "-");
  $: downloadName = `wibbo-${currentFormattedDate}.${image.extension}`
</script>

<h2>Image Details</h2>

<div>
  <div class="item">
    <img
      src={personIcon}
      alt="Author"
      class="icon"
    >

    <span>{image.author.username}</span>
  </div>

  <div class="item">
    <img
      src={calendarIcon}
      alt="Date"
      class="icon"
    >

    <span>{imageFormattedDate}</span>
  </div>

  <div class="item">
    <img
      src={locationIcon}
      alt="Location"
      class="icon"
    >

    <span>{image.location}</span>
  </div>

  <a
    download={downloadName}
    class="item"
    href={image.element.src}
  >
    <img
      src={downloadIcon}
      alt="Download"
      class="icon"
    >

    <span>{downloadName}</span>
  </a>
</div>

<style lang="scss">
  h2 {
    margin-bottom: 1em;
    margin-top: 1rem;
    text-align: center;
  }

  .item {
    align-items: center;
    color: inherit;
    display: flex;
    font-size: var(--font-size-5);

    &:not(:last-child) {
      margin-bottom: 0.3em;
    }
  }

  .icon {
    height: 1em;
    margin-right: 0.25em;
    width: 1em;

    @media (prefers-color-scheme: dark) {
      filter: invert(100%);
    }
  }
</style>
