<script lang="ts">
  export let classes: string = "";
  export let disabled: boolean = false;
  export let title: string | null = null;
  export let type: "button" | "submit" | "reset" = "button";
</script>

<button
  class={classes}
  {disabled}
  {title}
  {type}
  on:click
>
  <slot />
</button>

<style lang="postcss">
  :where(button) {
    background: var(--color-light);
    border-radius: var(--radius-3);
    color: var(--color-text-secondary);
    display: block;
    font-size: var(--font-size-fluid-1);
    padding: 0.5em 0.75em;

    @media (prefers-color-scheme: dark) {
      background: var(--gray-0);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.8;
    }

    &:hover {
      opacity: 0.8;
      transition: opacity 0.15s ease-in-out;
    }
  }
</style>
